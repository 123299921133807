import React, {useState} from "react";
import Loader from "./Loader"; // Import your loader component
import './styles.css';
import { Helmet } from 'react-helmet';

function PlateRecognition() {
  const [image, setImage] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [plateText, setPlateText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true); // Set loading state to true

    if (!image) {
      alert("Please upload an image");
      setIsLoading(false); // Set loading state to false on error
      return;
    }

    const formData = new FormData();
    formData.append("file", image); // "file" is the key used in the request

    try {
      // Send the POST request to the API
      const response = await fetch("https://api.mloomer.com/get-plate", {
        method: "POST",
        body: image, // Send the image directly
        headers: {
          "Content-Type": "image/jpeg",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      // Parse the JSON response from the server
      const plateInfo = await response.json();

      // Extract the base64 encoded image and plate text
      if (!plateInfo.text) {
        setPlateText("No Plate Found")
        setResultImage(null)
      } else {
        setResultImage(`data:image/jpeg;base64,${plateInfo.plate}`);
        setPlateText(plateInfo.text.join(", ")); // Join the detected text into a string
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false); // Set loading state to false after all actions
    }
  };

  return (

  <div>
    <Helmet>
      <title>LPR Recognition</title>
      <link rel="icon" type="image/x-icon" href="./favicon.ico" />
    </Helmet>
        <h2>License Plate Recognition</h2>

        {/* File upload form */}
        <form onSubmit={handleSubmit}>
          <input type="file" accept="image/*" onChange={handleImageChange} />
          <div><button type="submit" disabled={isLoading}>
            {isLoading ? <Loader /> : "Upload and Detect"}
          </button></div>
        </form>

        {/* Display the result if available */}
        {plateText && (
            <div>
              <h3>Detected Plate:</h3>
              <p>{plateText}</p>
              {resultImage && (<img src={resultImage} alt="Detected Plate" />)}
            </div>
        )}
      </div>
  );
}



export default PlateRecognition;


/*
#Step: Add Python to backend
  #done, api.mloomer.com/get-plate
#Step: Create front end with file uploader
  #done, lpr.mloomer.com
  #changes needed: auto size image, show upload as image too
#Step: Do writeup
  #charts?
 */